import React from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

//comps
import Links from "../../comps/Links";
import Script from "./Script";

//media
import Landing from "../../media/land.jpg";

const Comp = (props) => {
  return (
    <section className="landing-section primary-section" id="landing">
      <img
        className="landing-img"
        src={Landing}
        alt="Creating Calligraphy Mckenna Deckard working"
      />
      <Links links={props.links} />
      <div className="landing-wrapper">
        <div>
          <Script />
          <h1>Something for Everyone</h1>
          <div className="landing-button-wrap flex-align-cent flex-just-lft">
            <NavHashLink
              to={{
                pathname: "/contact",
                contactType: "Commission a piece",
              }}
            >
              <button className="primary">
                Start a Project
                <svg viewBox="0 0 24 24" id="landing-button-arrow">
                  <path
                    fill="currentColor"
                    d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                  />
                </svg>
              </button>
            </NavHashLink>
            <a href="https://www.etsy.com/shop/CreatingCallig">
              <button className="line">View Products</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comp;
