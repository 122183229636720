import React from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

//comps

//media
import Logo from "../../media/logo/Logo.js";

const Comp = () => {
  return (
    <React.Fragment>
      <section id="footer">
        <div className="footer-content">
          <div className=" flex-just-btw">
            <div className="footer-brand">
              <Logo />
              <p className="header-text">
                Creating Calligraphy embodies the philosophy that everyone has
                the ability to create.
              </p>
            </div>
            <div className="footer-content-sections-wrapper felx-align-top flex-just-cent">
              <div className="footer-content-section flex-col footer-social-piller">
                <p
                  className="header-text"
                  style={{ color: "var(--hightlight)" }}
                >
                  <b>Social</b>
                </p>

                <a
                  target="_blank"
                  href="https://www.instagram.com/creatingcallig/"
                  className="header-text"
                >
                  Instagram
                </a>
                <a
                  target="_blank"
                  href="https://www.etsy.com/shop/CreatingCallig"
                  className="header-text"
                >
                  Etsy
                </a>
                <a
                  target="_blank"
                  href="https://dribbble.com/mdeckar/about"
                  className="header-text"
                >
                  Dribbble
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/creatingcallig/"
                  className="header-text"
                >
                  Behance
                </a>
              </div>
              <div className="footer-content-section flex-col footer-piller">
                <p
                  className="header-text"
                  style={{ color: "var(--hightlight)" }}
                >
                  <b>Navigation</b>
                </p>

                <NavHashLink to="/#top" className="header-text">
                  Home
                </NavHashLink>
                <NavHashLink to="/#services" smooth className="header-text">
                  Services
                </NavHashLink>
                <NavHashLink to="/#products" smooth className="header-text">
                  Products
                </NavHashLink>
                <NavHashLink to="/Contact#top" className="header-text">
                  Contact
                </NavHashLink>
              </div>
              <div className="footer-content-section flex-col">
                <p
                  className="header-text"
                  style={{ color: "var(--hightlight)" }}
                >
                  <b>Contact</b>
                </p>

                <NavHashLink to="/contact#top" className="header-text">
                  Form
                </NavHashLink>
                <a
                  href="mailto:mckenna.creatingcalligraphy@gmail.com"
                  className="header-text"
                >
                  Email
                </a>
                <a href="tel:765-461-0606" className="header-text">
                  Phone
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/creatingcallig/"
                  className="header-text"
                >
                  Instagram
                </a>
              </div>
            </div>
            <div className="footer-content-section footer-brand footer-com">
              <p className="header-text" style={{ color: "var(--hightlight)" }}>
                <b>Thank you ♡</b>
              </p>
              <p
                style={{ margin: "-4px 0 0", diplay: "block", height: "auto" }}
                className="header-text"
              >
                It’s people like you who helped me to turn this hobby into a
                living. Thank you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer className="page-section flex-align-cent ">
        <div className="footer-footer flex-align-cent flex-just-btw">
          <div>
            © McKenna Deckard {new Date().getFullYear()}, All rights reserved.
          </div>
          <div className="footer-social-links flex-align-cent">
            <a href="https://www.etsy.com/shop/CreatingCallig" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
              >
                <path d="M18 2H6C3.8 2 2 3.8 2 6v12c0 2.2 1.8 4 4 4h12c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4zm-1.3 16s-2.3-.1-3.3-.1H9l-2.6.1v-.7l.9-.2c.6-.1.7-.2.7-.7 0 0 .1-1.6.1-4.3 0-2.7-.1-4.4-.1-4.4 0-.5-.2-.6-.8-.7l-.8-.2v-.7l2.6.1h4.9c1 0 2.6-.2 2.6-.2s-.1 1-.1 3.4h-.7l-.2-.8c-.3-1.1-.7-1.6-1.4-1.6h-3.8c-.3 0-.3 0-.3.2v4.4s1.6 0 2.4-.1c.4.1.9-.3.9-.7v-.1l.2-1h.7l-.1 2.1.1 2.2h-.7l-.2-.8c-.1-.5-.5-.8-.9-.8-.6-.1-2.4-.1-2.4-.1V16c0 .7.4 1 1.2 1h2.6c.9.1 1.8-.4 2.1-1.2l.7-1.4h.6c-.1.4-.4 3-.5 3.6z"></path>
              </svg>
            </a>
            <a href="https://www.instagram.com/creatingcallig/" target="_blank">
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                />
              </svg>
            </a>
            <a href="https://dribbble.com/mdeckar/about" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 32 32"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M16 3C8.823 3 3 8.823 3 16s5.823 13 13 13c7.163 0 13-5.823 13-13S23.163 3 16 3zm8.587 5.992c1.55 1.89 2.481 4.3 2.51 6.91-.367-.071-4.033-.818-7.727-.353-.085-.183-.155-.38-.24-.578a33.027 33.027 0 00-.733-1.608c4.089-1.663 5.95-4.06 6.19-4.37zM16 4.918c2.82 0 5.4 1.057 7.36 2.791-.197.282-1.875 2.524-5.823 4.005-1.819-3.342-3.835-6.077-4.145-6.5A11.292 11.292 0 0116 4.918zM11.277 5.96c.296.395 2.27 3.144 4.117 6.415-5.189 1.382-9.771 1.354-10.265 1.354.72-3.44 3.046-6.303 6.148-7.769zM4.889 16.014v-.338c.48.014 5.866.084 11.407-1.58.324.62.62 1.255.902 1.89-.14.042-.296.084-.437.127-5.724 1.847-8.77 6.895-9.023 7.318a11.078 11.078 0 01-2.849-7.417zM16 27.111c-2.566 0-4.935-.875-6.81-2.34.197-.41 2.453-4.753 8.714-6.938.028-.014.042-.014.07-.028 1.565 4.046 2.2 7.444 2.369 8.417a10.92 10.92 0 01-4.343.889zm6.19-1.904c-.113-.677-.705-3.92-2.158-7.91 3.483-.55 6.529.353 6.91.48-.48 3.087-2.256 5.752-4.752 7.43z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="mailto:mckenna.creatingcalligraphy@gmail.com"
              target="_blank"
            >
              <svg viewBox="0 0 24 24" fill="currentColor">
                <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Comp;
