import React, { useState, useEffect } from "react";
import { NavHashLink } from "react-router-hash-link";
import { useHistory } from "react-router-dom";

import "./style.css";
import "firebase/analytics";

//comps

//media
import Mail from "../../media/icons/Mail";
import Sign from "../../media/icons/Sign";
import Wedding from "../../media/icons/Wedding";
import Food from "../../media/icons/Food";
import Mirror from "../../media/icons/Mirror";
import Event from "../../media/icons/Event";

//firebase
import firebase from "../../firebase";
// import "firebase/analytics";
const db = firebase.firestore();
const analytics = firebase.analytics();

const Comp = (props) => {
  const [drop, setDrop] = useState(false);
  const [projects, setProjects] = useState(props.services);
  const [name, setName] = useState();
  const [email, setEmail] = useState(props.email);
  const [subject, setSubject] = useState(props.type);
  const [type, setType] = useState(props.sub);
  const [message, setMessage] = useState();
  const [maillingList, setMaillingList] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sent, setSent] = useState(false);

  let history = useHistory();

  const subjects = [
    {
      name: "Commission a piece",
      plh: "Tell me about your project (Quantity, Budget, Timeline)…",
    },
    {
      name: "Question about a product",
      plh: "What is your question (please specify the product)…",
    },
    {
      name: "Write a review",
      plh:
        "What did you think (please specify the product & where you bought it)…",
    },
    { name: "General Inquiry", plh: "What's on your mind..." },
    { name: "Other", plh: "What's on your mind..." },
  ];

  const other = {
    icon: "",
    title: "Other",
    data: "Other",
  };

  useEffect(() => {
    let projArr = projects;
    projArr.push(other);
    setProjects(projArr);
  }, [projects]);

  const submitMssg = (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");

    // console.log("sent");
    firebase.analytics().logEvent("Message_Sent");

    db.collection("stats")
      .doc("overview")
      .get()
      .then((doc) => {
        let cnt = doc.data().msgCount;
        let id = `MSG#${cnt}-${Math.random()}`;

        db.collection("Messages")
          .doc(id)
          .set({
            name: name,
            email: email,
            subject: subject,
            projectType: type ? type : "N/A",
            text: message,
            subscribe: maillingList,
          });
      })
      .then((doc) => {
        setSent(true);
        setLoading(false);
        setName("");
        setEmail("");
        setMessage("");
        setError("");
        history.push({
          pathname: "/",
          submitted: true,
        });
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        setLoading(false);
        setError(error.message);
      });
  };

  return (
    <section className="primary-section contact-form-section">
      <div className="secondary-section contact-form-back" />
      <div className=" flex-col flex-align-top">
        <div className="bread-crumbs flex-align-cent flex-just-lft">
          <NavHashLink to="/#top">Home</NavHashLink>
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
            />
          </svg>
          Contact
        </div>
        <div className="contact-form-back-card">
          <form onSubmit={(e) => submitMssg(e)}>
            <div className="form-header">
              <h3>Contact Form</h3>
              <p className="header-text">
                Please fill out the following form and I will get back to you in
                1 - 2 business days.
              </p>
            </div>
            <div className="contact-first-line">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name"
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                type="email"
                id="emai"
                name="email"
                placeholder="Your email"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div
              className={`dropdown-wrapper ${!drop ? "" : "active"}`}
              onClick={(e) => setDrop(!drop)}
            >
              <div className="form-input-wrap">
                <div className="dropdown flex-align-cent flex-just-btw">
                  {!subject ? (
                    <p className="placeholder-text" style={{ opacity: 0.5 }}>
                      Choose a subject
                    </p>
                  ) : (
                    <p className="header-text" style={{ color: "black" }}>
                      {subject}
                    </p>
                  )}
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                    />
                  </svg>
                </div>
              </div>
              {!drop ? (
                ""
              ) : (
                <ul className="dropdown-list">
                  {subjects.map((sub) => (
                    <li
                      key={Math.random()}
                      onClick={(e) => setSubject(sub.name)}
                      className="header-text"
                      style={{ opacity: sub.name == subject ? 1 : 0.7 }}
                    >
                      {sub.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {subject != "Commission a piece" ? null : (
              <div className="propjects-container-wrapper">
                <p className="header-text subject-tipe-header">Product type:</p>
                <div className="subject-blocks">
                  {projects.map((tile) => {
                    const MyComp = tile.icon;
                    return (
                      <div
                        key={Math.random()}
                        className={`form-block flex-col flex-just-cent ${
                          type == tile.data ? "active-type-tile" : ""
                        }`}
                        onClick={(e) => setType(tile.data)}
                      >
                        {tile.icon ? <MyComp /> : ""}
                        {tile.data}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div>
              <textarea
                id="text"
                name="text"
                placeholder={
                  subject && subjects.find((x) => x.name === subject)
                    ? subjects.find((x) => x.name === subject).plh
                    : `What is on your mind...`
                }
                required
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
            {!error ? "" : <p style={{ color: "red" }}>{error}</p>}
            <div className="form-action-bar">
              <div
                className="flex-align-cent flex-just-lft subscribe-check-wrap"
                onClick={(e) => setMaillingList(!maillingList)}
              >
                <svg viewBox="0 0 24 24">
                  {maillingList ? (
                    <path
                      fill="#000"
                      d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
                    ></path>
                  ) : (
                    <path
                      fill="#9da5af"
                      d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
                    ></path>
                  )}{" "}
                </svg>
                <p className="header-text">
                  I would like to receive deals and promotions.
                </p>
              </div>

              <button className="primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Comp;

//<button
//   onClick={() => {
//     history.push({
//       pathname: "/",
//       submitted: true,
//     });
//   }}
// >
//   test
// </button>
