import React, { useEffect } from "react";

//sections
import ContactForm from "../../sections/ContactForm";
import Footer from "../../comps/Footer";

const App = (props) => {
  const type = props.location.contactType;
  const email = props.location.email;
  const sub = props.location.subType;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ContactForm
        type={type}
        sub={sub}
        email={email}
        services={props.services}
      />
      <Footer />
    </React.Fragment>
  );
};

export default App;
