import React, { useRef, useState, useEffect } from "react";

import Header from "../../comps/Header";
import Landing from "../../sections/Landing";
import Services from "../../sections/Services";
import Products from "../../sections/Products";
import Testimonials from "../../sections/Testimonials";
import Contact from "../../sections/Contact";
import Footer from "../../comps/Footer";
import SubmitMsg from "../../comps/SubmitMsg";

//functions
import useWindowDimensions from "../../sections/Testimonials/useWindowDimensions.js";

const Page = (props) => {
  const [positions, setPositions] = useState(0);
  const { height, width } = useWindowDimensions();
  const landRef = useRef(null);
  const servRef = useRef(null);
  const prodRef = useRef(null);
  const testRef = useRef(null);
  const contRef = useRef(null);

  //funciton that sets positoins for each seciton
  const handleScroll1 = () => {
    const landTop = landRef.current.getBoundingClientRect().top;
    const servTop = servRef.current.getBoundingClientRect().top;
    const prodTop = prodRef.current.getBoundingClientRect().top;
    const testTop = testRef.current.getBoundingClientRect().top;
    const contTop = contRef.current.getBoundingClientRect().top;

    setPositions([landTop, servTop, prodTop, testTop, contTop]);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll1, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll1);
    };
  }, []);

  //resets postions on window resize

  return (
    <React.Fragment>
      {props.location.submitted ? <SubmitMsg /> : ""}
      <Header position={positions} />
      <div ref={landRef}>
        <Landing links={props.links} />
      </div>
      <div ref={servRef}>
        <Services services={props.services} />
      </div>
      <div ref={prodRef}>
        <Products products={props.products} />
      </div>
      <div ref={testRef}>
        <Testimonials testimonials={props.testimonials} />
      </div>
      <div ref={contRef}>
        <Contact />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Page;
