import React from "react";
import "./style.css";

//comps

//media

const Comp = (props) => {
  return (
    <a href={props.data.link} className="product-card">
      <div>
        {props.data.img ? (
          <img
            className="product-image"
            src={props.data.img}
            alt={props.data.title + " image for Creating Calligraphy"}
          />
        ) : (
          ""
        )}
        <button className="fog">
          View all
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
            />
          </svg>
        </button>
      </div>
      <h3>{props.data.title}</h3>
      <p>{props.data.text}</p>
    </a>
  );
};

export default Comp;
