import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import "./App.css";
import "./themes.css";
import "./fonts.css";

//pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";

//media
import Mail from "./media/icons/Mail";
import Sign from "./media/icons/Sign";
import Wedding from "./media/icons/Wedding";
import Food from "./media/icons/Food";
import Mirror from "./media/icons/Mirror";
import Event from "./media/icons/Event";
import BlueImg from "./media/blue2.jpg";
import SignImg from "./media/sign2.jpg";
import TagImg from "./media/tag.jpg";
import CardImg from "./media/card2.jpg";
import Insta from "./media/icons/Instagram";
import Etsy from "./media/icons/Etsy";
import Dribbble from "./media/icons/Dribbble";
import Behance from "./media/icons/Behance";

const App = () => {
  const [previousLocation, setPreviousLocation] = useState();

  //Modals
  let location = useLocation();
  useEffect(() => {
    if (!(location.state && location.state.modal)) {
      setPreviousLocation(location);
    }
  }, [location]);
  const isModal =
    location.state && location.state.modal && previousLocation !== location
      ? true
      : false;

  const links = [
    {
      icon: Insta,
      title: "Instagram",
      handle: "@creatingcallig",
      link: "https://www.instagram.com/creatingcallig/",
    },
    {
      icon: Etsy,
      title: "Etsy",
      handle: "CreatingCallig",
      link: "https://www.etsy.com/shop/CreatingCallig",
    },
    {
      icon: Dribbble,
      title: "Dribbble",
      handle: "Creating Calligraphy",
      link: "https://dribbble.com/mdeckar/about",
    },
    // {
    //   icon: Behance,
    //   title: "Behance",
    //   handle: "Mckenna Deckard",
    //   link: "https://dribbble.com/mdeckar/about",
    // },
  ];
  const services = [
    {
      icon: Sign,
      title: "Tailored Signs",
      text: "Personalized signs, mirrors, and wall décor",
      data: "Signs",
    },
    {
      icon: Wedding,
      title: "Weddings",
      text: "Premium stationery (invitations, place cards, ...)",
      data: "Weddings",
    },
    {
      icon: Mail,
      title: "Custom Cards",
      text: "Quality Cards, Envelopes, and Seals all customized",
      data: "Cards",
    },
    {
      icon: Food,
      title: "Menus",
      text: "Hand drawn chalk board and table menus",
      data: "Menus",
    },
    {
      icon: Mirror,
      title: "Mirrors",
      text: "Decorative mirrors & frames for events and home décor",
      data: "Mirrors",
    },
    {
      icon: Event,
      title: "Events",
      text: "Custom pieces for events and large parties",
      data: "Events",
    },
  ];
  const products = [
    {
      img: CardImg,
      link: "https://www.etsy.com/shop/CreatingCallig?section_id=32588609",
      title: "Greeting Cards",
      text:
        "Greeting Cards designed for any occasion. Available in the most common sizes: A2, A6, and A7.",
    },
    {
      img: BlueImg,
      link: "https://www.etsy.com/shop/CreatingCallig?section_id=32917014",
      title: "Printables",
      text:
        "Printables created for immediate download and use. Products delivered as a downloadable PDF file, formatted for printing.",
    },
    {
      img: TagImg,
      link: "https://www.etsy.com/shop/CreatingCallig?section_id=32932519",
      title: "Gift Tags & Place Cards ",
      text:
        "Choose from a selection of Gift Tags and Place Cards. Submit your guest list and preferred color, size & style.",
    },
    {
      img: SignImg,
      link: "https://www.etsy.com/shop/CreatingCallig?section_id=32970173",
      title: "Decorative Signs",
      text:
        "Decorative Signs available in various sizes, shapes, and materials to fit your stylistic needs and preferences.",
    },
  ];
  const testimonials = [
    {
      text:
        "As someone who loves to craft, I can definitively say her work is amazing! Hard to believe it's all by hand!",
      name: "Ryleigh Bowlin",
      title: "Professional Mom",
      review: [1, 1, 1, 1, 1],
    },
    {
      text:
        "Your greeting cards are perfect for heartfelt thank-yous and interview follow-ups. Love them!",
      name: "Audrey Deckar",
      title: "DIYer",
      review: [1, 1, 1, 1, 0],
    },
    {
      text:
        "Professional, creative, and an absolute delight to work with. I highly recommend working with Mckenna!",
      name: "Page Manson",
      title: "Wedding Planner",
      review: [1, 1, 1, 1, 1],
    },
    {
      text:
        "I love these cards! It is so hard to find hand made cards of this caliber now days",
      name: "Alice VanMeter",
      title: "Author",
      review: [1, 1, 1, 1, 1],
    },
    {
      text:
        "She is very communicative and a delight to work with throughout the entire creative process!",
      name: "Bill Wolfe",
      title: "Photographer",
      review: [1, 1, 1, 1, 0],
    },
  ];

  return (
    <div className="App">
      <Switch location={isModal ? previousLocation : location}>
        <Route
          exact
          path="/"
          render={(props) => (
            <Home
              {...props}
              services={services}
              products={products}
              testimonials={testimonials}
              links={links}
            />
          )}
        />
        <Route
          exact
          path="/contact"
          render={(props) => <Contact {...props} services={services} />}
        />
      </Switch>
    </div>
  );
};

export default App;
