import React from "react";

const Comp = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.038 2.199c-.037.097-.049.426-.027.732l.038.556.858.087c.912.093 1.38.343 1.649.88.221.443.221 14.693 0 15.136-.269.537-.737.787-1.65.88l-.857.087V21.958l8.005.033c6.31.026 8.032-.001 8.132-.127.193-.242.936-5.585.797-5.727-.064-.066-.332-.101-.597-.079l-.48.04-.4 1.21-.476 1.442c-.13.396-.813 1.109-1.343 1.4-.483.267-.653.28-3.626.28-4.332 0-4.11.238-4.12-4.427l-.008-3.026h2.075c1.73 0 2.146.038 2.5.224.596.312.886.777.98 1.574l.082.687.526.039.527.039V8.25l-.527.039-.526.039-.075.804c-.086.919-.35 1.378-.987 1.712-.354.185-.77.222-2.5.223H8.933L8.94 8.04c.008-3.294.074-3.719.653-4.16.332-.253.5-.266 3.283-.266h2.932l.589.355c.825.5 1.113 1.117 1.183 2.543l.055 1.114h.535c.294 0 .562-.045.595-.1.034-.056.152-1.234.264-2.62.158-1.957.17-2.56.056-2.702-.218-.269-15.947-.276-16.047-.006z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Comp;
