import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

//comps
import ServiceCard from "./ServiceCard";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

//functions
import useWindowDimensions from "../../sections/Testimonials/useWindowDimensions";

const Comp = (props) => {
  const [show, doShow] = useState(false);
  const { height, width } = useWindowDimensions();
  const ref = useRef(null);
  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const elementPostion = topPos(ref.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + 0.8 * window.innerHeight;
      let logic = elementPostion < scrollPos;
      doShow(logic);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const progClick = (className) => {
    var aNode = document.getElementsByClassName(className)[0];
    aNode.click();
  };

  return (
    <div
      ref={ref}
      className={`${!show ? "" : "active-test"}`}
      style={{ position: "relative" }}
    >
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={props.data.length + 1}
        visibleSlides={width > 900 ? 3 : width > 600 ? 2 : 1}
      >
        <Slider>
          {props.data.map((service, i) => (
            <Slide index={i} key={`servoce-${i}`}>
              <ServiceCard i={i} data={service} />
            </Slide>
          ))}
          <Slide index={props.data.length}>
            <div className="testimonial-card testimonial-card-end flex-col flex-align-cent flex-just-cent">
              <div
                style={{
                  background: "rgb(185, 202, 212)",
                  marginBottom: "16px",
                }}
                className="active-service-card services-icon-wrap  flex-align-cent flex-just-cent"
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9.75 20.85C11.53 20.15 11.14 18.22 10.24 17C9.35 15.75 8.12 14.89 6.88 14.06C6 13.5 5.19 12.8 4.54 12C4.26 11.67 3.69 11.06 4.27 10.94C4.86 10.82 5.88 11.4 6.4 11.62C7.31 12 8.21 12.44 9.05 12.96L10.06 11.26C8.5 10.23 6.5 9.32 4.64 9.05C3.58 8.89 2.46 9.11 2.1 10.26C1.78 11.25 2.29 12.25 2.87 13.03C4.24 14.86 6.37 15.74 7.96 17.32C8.3 17.65 8.71 18.04 8.91 18.5C9.12 18.94 9.07 18.97 8.6 18.97C7.36 18.97 5.81 18 4.8 17.36L3.79 19.06C5.32 20 7.88 21.47 9.75 20.85M20.84 5.25C21.06 5.03 21.06 4.67 20.84 4.46L19.54 3.16C19.33 2.95 18.97 2.95 18.76 3.16L17.74 4.18L19.82 6.26M11 10.92V13H13.08L19.23 6.85L17.15 4.77L11 10.92Z"
                  />
                </svg>
              </div>

              <p className="header-text" style={{ color: "var(--hightlight)" }}>
                <b>Something else</b>
              </p>
              <p className="header-text">
                Looking to create something special? Let’s talk!
              </p>
              <NavHashLink
                className="service-card-more-link"
                to={{
                  pathname: `/contact`,
                  contactType: "Commission a piece",
                  subType: "Other",
                }}
              >
                <button style={{ marginTop: "16px" }} className="line">
                  Get started
                </button>
              </NavHashLink>
            </div>
          </Slide>
        </Slider>
        <ButtonBack className="car-button"></ButtonBack>
        <ButtonNext className="car-button"></ButtonNext>
      </CarouselProvider>
      <div className="flex-just-btw flex-align-cent hint-wrapper-services">
        <button
          className="mobile-services-button"
          onClick={(e) => progClick("carousel__back-button")}
        >
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12M14,7L9,12L14,17V7Z"
            />
          </svg>
          Back
        </button>
        <button
          className="mobile-services-button"
          onClick={(e) => progClick("carousel__next-button")}
        >
          Next
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12M4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12M10,17L15,12L10,7V17Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Comp;
