import React from "react";
import "./style.css";

//comps
// import ServiceCard from "../../comps/ServiceCard";
import Header from "../../comps/SectionHeader";
import Form from "../../comps/ContactForm";

//media
import Insta from "../../media/icons/Instagram";

const Comp = () => {
  return (
    <section
      className="secondary-section page-section contact-section-wrap "
      id="contact"
    >
      <div>
        <div className="section-header">
          <span>Contact</span>

          <h3 className="header-2">Let's Make Something Together</h3>
          <p className="header-text ">
            Have a special project in mind, interested in a custom product, or
            just want to say hello? Enter your email to get started.
          </p>
        </div>
        <Form />
      </div>
    </section>
  );
};

export default Comp;
