import React from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

const Comp = (props) => {
  const MyComp = props.data.icon;

  return (
    <NavHashLink
      to={{
        pathname: `/contact`,
        contactType: "Commission a piece",
        subType: props.data.data,
      }}
      className={`service-card-wrapper ${
        props.active ? "active-service-card" : ""
      }`}
      href={props.data.link}
    >
      <div className="services-icon-wrap flex-align-cent flex-just-cent">
        <MyComp />
      </div>
      <h4 className="header-3">{props.data.title}</h4>
      <p className="header-text">{props.data.text}</p>
      <button className="line">Learn More</button>
    </NavHashLink>
  );
};

export default Comp;

//       onMouseEnter={() => props.setActive(props.i)}
// onMouseLeave={() => props.setActive(1)}
