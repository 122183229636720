import React, { useLayoutEffect, useRef, useState } from "react";
import "./style.css";
//comps
import Icon from "./icon";

//media

const Comp = (props) => {
  const [show, doShow] = useState(false);
  const ref1 = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const elementPostion = topPos(ref1.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + 0.8 * window.innerHeight;
      doShow(elementPostion < scrollPos);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const icons = props.links;

  return (
    <div className="links-cont flex-col" ref={ref1}>
      <span className="links-cont-headr">Follow:</span>
      <div
        className={`links-wrap flex-align-cent flex-just-btw ${
          !show ? "" : "show-icons"
        }`}
      >
        {icons.map((icon, i) => (
          <Icon key={`link-item-${i}`} data={icon} />
        ))}
      </div>
    </div>
  );
};

export default Comp;

// <a
//   className="flex-align-cent flex-just-cent link-item-container mobile-only"
//   target="_blank"
//   href="mailto:mckenna.creatingcalligraphy@gmail.com"
// >
//   <div className="links-icon-wrap flex-align-cent flex-just-cent">
//     <svg viewBox="0 0 24 24">
//       <path
//         fill="currentColor"
//         d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"
//       />
//     </svg>
//   </div>
// </a>
//
