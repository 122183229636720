// import * as firebase from "firebase/app";
import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBfEfw4xvsJ0Hj1gHz60LxcgZlARGrGkO8",
  authDomain: "creating-calligraphy.firebaseapp.com",
  projectId: "creating-calligraphy",
  storageBucket: "creating-calligraphy.appspot.com",
  messagingSenderId: "348704274317",
  appId: "1:348704274317:web:c269b7138a4962f1726fc4",
  measurementId: "G-ZVMV39XTS1",
};
firebase.initializeApp(firebaseConfig);

// export const db = firebase.firestore();
export default firebase;
