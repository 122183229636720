import React from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

//comps
import ServiceCards from "../../comps/ServiceCards2";
import Header from "../../comps/SectionHeader";

const Comp = (props) => {
  const HeaderComp = (
    <p className="header-text ">
      Here are my most popular services, but please don’t hesitate to{" "}
      <NavHashLink
        to={{ pathname: `/contact`, contactType: "Commission a piece" }}
        style={{ textDecoration: "underline" }}
      >
        reach out
      </NavHashLink>{" "}
      if you have something else in mind.
    </p>
  );
  const header = {
    section: "Services",
    title: "Create something unique",
    action: HeaderComp,
  };

  return (
    <section className="secondary-section page-section" id="services">
      <div>
        <Header data={header} />
        <ServiceCards data={props.services} />
      </div>
    </section>
  );
};

export default Comp;
