import React from "react";

const Comp = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 3C8.823 3 3 8.823 3 16s5.823 13 13 13c7.163 0 13-5.823 13-13S23.163 3 16 3zm8.587 5.992c1.55 1.89 2.481 4.3 2.51 6.91-.367-.071-4.033-.818-7.727-.353-.085-.183-.155-.38-.24-.578a33.027 33.027 0 00-.733-1.608c4.089-1.663 5.95-4.06 6.19-4.37zM16 4.918c2.82 0 5.4 1.057 7.36 2.791-.197.282-1.875 2.524-5.823 4.005-1.819-3.342-3.835-6.077-4.145-6.5A11.292 11.292 0 0116 4.918zM11.277 5.96c.296.395 2.27 3.144 4.117 6.415-5.189 1.382-9.771 1.354-10.265 1.354.72-3.44 3.046-6.303 6.148-7.769zM4.889 16.014v-.338c.48.014 5.866.084 11.407-1.58.324.62.62 1.255.902 1.89-.14.042-.296.084-.437.127-5.724 1.847-8.77 6.895-9.023 7.318a11.078 11.078 0 01-2.849-7.417zM16 27.111c-2.566 0-4.935-.875-6.81-2.34.197-.41 2.453-4.753 8.714-6.938.028-.014.042-.014.07-.028 1.565 4.046 2.2 7.444 2.369 8.417a10.92 10.92 0 01-4.343.889zm6.19-1.904c-.113-.677-.705-3.92-2.158-7.91 3.483-.55 6.529.353 6.91.48-.48 3.087-2.256 5.752-4.752 7.43z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Comp;
