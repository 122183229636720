import React from "react";
import "./style.css";

const Comp = (props) => {
  const MyComp = props.data.icon;

  return (
    <a
      className="flex-align-cent flex-just-cent link-item-container"
      target="_blank"
      href={props.data.link}
    >
      <div className="links-icon-wrap flex-align-cent flex-just-cent">
        <MyComp />
      </div>
      <div className="link-text-wrap">
        <span>{props.data.title}</span>
        <span>{props.data.handle}</span>
      </div>
    </a>
  );
};

export default Comp;
