import React from "react";
import "./style.css";

//comps

//media

const Comp = (props) => {
  return (
    <div className="section-header">
      <span>{props.data.section}</span>
      <div className="page-section-header flex-align-top flex-just-btw">
        <div>
          <h3 className="header-2">{props.data.title}</h3>
        </div>
        <div>{props.data.action}</div>
      </div>
    </div>
  );
};

export default Comp;
