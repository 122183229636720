import React from "react";
// import { NavHashLink } from "react-router-hash-link";

import "./style.css";

//comps
import Products from "../../comps/Products";
import Header from "../../comps/SectionHeader";

const Comp = (props) => {
  const HeaderComp = (
    <a href="https://www.etsy.com/shop/CreatingCallig">
      <button className="line">
        More products
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
          />
        </svg>
      </button>
    </a>
  );
  const header = {
    section: "Products",
    title: "Products for every occasion",
    action: HeaderComp,
  };

  return (
    <section className="page-section" id="products">
      <div>
        <Header data={header} />
        <Products data={props.products} />
        <a href="https://www.etsy.com/shop/CreatingCallig">
          <button className="products-more-button primary">
            Explore all products
          </button>
        </a>
      </div>
    </section>
  );
};

export default Comp;
