import React, { useLayoutEffect, useRef, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

//comps

//media
import Insta from "../../media/icons/Instagram";

const Comp = () => {
  const [email, setEmail] = useState();
  const [show, doShow] = useState(false);
  const [focus, setFocus] = useState(false);
  const ref12 = useRef(null);
  const input = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const elementPostion = topPos(ref12.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + 0.7 * window.innerHeight;
      let logic = elementPostion < scrollPos;
      doShow(logic);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div
      className={`flex-align-cent flex-just-cent ${
        !show ? "" : "active-test"
      } `}
      ref={ref12}
      onClick={() => input.current.focus()}
    >
      <div
        className={`contact-input-wrap flex-align-cent flex-just-btw ${
          !focus ? "" : "active-input"
        }`}
      >
        <input
          ref={input}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          type="email"
          placeholder="What's your email?"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <NavHashLink to={{ pathname: `/contact`, email: email }}>
          <button className="primary">Continue</button>
        </NavHashLink>
      </div>
    </div>
  );
};

export default Comp;
