import React, { useLayoutEffect, useRef, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

//comps
import Testimonial from "./Testimonial";
import Header from "../../comps/SectionHeader";

//functions
import useWindowDimensions from "./useWindowDimensions";

const Comp = (props) => {
  const [show, doShow] = useState(false);
  const { height, width } = useWindowDimensions();
  const HeaderComp = (
    <div className=" test-button-wrap flex-align-cent flex-just-cent">
      <button
        className="line"
        onClick={(e) => progClick("carousel__back-button")}
      >
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
          />
        </svg>
      </button>
      <button
        className="primary"
        onClick={(e) => progClick("carousel__next-button")}
      >
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
          />
        </svg>
      </button>
    </div>
  );
  const header = {
    section: "Testimonials",
    title: "What our customers have to say",
    action: HeaderComp,
  };

  const progClick = (className) => {
    var aNode = document.getElementsByClassName(className)[1];
    aNode.click();
  };

  const ref12 = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const elementPostion = topPos(ref12.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + 0.8 * window.innerHeight;
      let logic = elementPostion < scrollPos;
      doShow(logic);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <section
      className={`primary-section page-section ${!show ? "" : "active-test"}`}
      id="testimonials"
    >
      <div>
        <Header data={header} />
        <div className="test-cards-wrap" ref={ref12}>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={6}
            visibleSlides={width > 900 ? 3 : width > 600 ? 2 : 1}
          >
            <Slider>
              {props.testimonials.map((testimonial, i) => (
                <Slide index={i} key={`test-item-${i}`}>
                  <Testimonial data={testimonial} />
                </Slide>
              ))}
              <Slide index={0}>
                <div className="testimonial-card testimonial-card-end flex-col flex-align-cent flex-just-cent">
                  <div
                    style={{
                      background: "rgb(185, 202, 212)",
                      marginBottom: "16px",
                    }}
                    className="active-service-card services-icon-wrap  flex-align-cent flex-just-cent"
                  >
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M5.8 21L7.4 14L2 9.2L9.2 8.6L12 2L14.8 8.6L22 9.2L18.8 12H18C17.3 12 16.6 12.1 15.9 12.4L18.1 10.5L13.7 10.1L12 6.1L10.3 10.1L5.9 10.5L9.2 13.4L8.2 17.7L12 15.4L12.5 15.7C12.3 16.2 12.1 16.8 12.1 17.3L5.8 21M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                      />
                    </svg>
                  </div>

                  <p
                    className="header-text"
                    style={{ color: "var(--hightlight)" }}
                  >
                    <b>Write a review</b>
                  </p>
                  <p className="header-text">
                    Any and all feedback is welcome and appreciated!
                  </p>
                  <NavHashLink
                    to={{ pathname: `/contact`, contactType: "Write a review" }}
                  >
                    <button style={{ marginTop: "16px" }} className="line">
                      Get started
                    </button>
                  </NavHashLink>
                </div>
              </Slide>
            </Slider>

            <ButtonBack className="car-button"></ButtonBack>
            <ButtonNext className="car-button"></ButtonNext>
          </CarouselProvider>
        </div>
      </div>
    </section>
  );
};

export default Comp;
// <div className="flex-align-cent flex-just-btw test-cards-wrap"></div>
