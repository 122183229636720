import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { NavHashLink } from "react-router-hash-link";

import "./style.css";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const Page = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const col = ["#EDDFCE", "#EDDFCE", "#EDDFCE", "#FFFFFF", "#FFFFFF"];

  return (
    <NavHashLink
      className="modal-wrapper flex-align-cent flex-just-cent"
      to={{
        pathname: `/`,
        submitted: false,
      }}
    >
      <div style={{ zIndex: 110 }} className="sent-animation">
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          colors={col}
        />
      </div>
      <div className="testimonial-card testimonial-card-end flex-col flex-align-cent flex-just-cent">
        <div
          style={{
            background: "rgb(185, 202, 212)",
            marginBottom: "16px",
          }}
          className="active-service-card services-icon-wrap  flex-align-cent flex-just-cent"
        >
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M14.53 1.45L13.45 2.53L15.05 4.13C15.27 4.38 15.38 4.67 15.38 5S15.27 5.64 15.05 5.86L11.5 9.47L12.5 10.55L16.13 6.94C16.66 6.35 16.92 5.7 16.92 5C16.92 4.3 16.66 3.64 16.13 3.05L14.53 1.45M10.55 3.47L9.47 4.55L10.08 5.11C10.3 5.33 10.41 5.63 10.41 6S10.3 6.67 10.08 6.89L9.47 7.45L10.55 8.53L11.11 7.92C11.64 7.33 11.91 6.69 11.91 6C11.91 5.28 11.64 4.63 11.11 4.03L10.55 3.47M21 5.06C20.31 5.06 19.67 5.33 19.08 5.86L13.45 11.5L14.53 12.5L20.11 6.94C20.36 6.69 20.66 6.56 21 6.56S21.64 6.69 21.89 6.94L22.5 7.55L23.53 6.47L22.97 5.86C22.38 5.33 21.72 5.06 21 5.06M7 8L2 22L16 17L7 8M19 11.06C18.3 11.06 17.66 11.33 17.06 11.86L15.47 13.45L16.55 14.53L18.14 12.94C18.39 12.69 18.67 12.56 19 12.56C19.33 12.56 19.63 12.69 19.88 12.94L21.5 14.53L22.55 13.5L20.95 11.86C20.36 11.33 19.7 11.06 19 11.06Z"
            />
          </svg>
        </div>

        <p className="header-text" style={{ color: "var(--hightlight)" }}>
          <b>Message Sent</b>
        </p>
        <p className="header-text">
          A confirmation email has been sent! (check your spam folder)
        </p>
        <NavHashLink
          className="service-card-more-link"
          to={{
            pathname: `/`,
            submitted: false,
          }}
        >
          <button style={{ marginTop: "16px" }} className="line">
            close
          </button>
        </NavHashLink>
      </div>
    </NavHashLink>
  );
};

export default Page;
