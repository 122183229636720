import React, { useState, useEffect, useRef } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

//comps

//media
import Menu from "../../media/icons/Menu";
import LogoText from "../../media/logo/LogoText.js";
import Logo from "../../media/logo/Logo.js";

//functions
import useDocumentScrollThrottled from "./useDocumentScrollThrottled";
import useWindowDimensions from "../../sections/Testimonials/useWindowDimensions.js";

const Comp = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);
  const [underline, setUnderline] = useState(0);
  const [active, setActive] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [navArr, setNavArr] = useState([
    { name: "Home", ref: useRef(null) },
    { name: "Services", ref: useRef(null) },
    { name: "Products", ref: useRef(null) },
    { name: "Testimonials", ref: useRef(null) },
  ]);
  const { height, width } = useWindowDimensions();

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 0;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);
    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  //nav highlight bar
  const setBar = () => {
    if (navArr[0].ref) {
      let tempArr = navArr;
      let positionStore = 0;
      tempArr.forEach((item, i) => {
        let itemWidth = item.ref.current.getBoundingClientRect().width;
        tempArr[i].barWidth = 0.2 * itemWidth;
        tempArr[i].barPosition = positionStore + 0.4 * itemWidth;

        positionStore += itemWidth;
      });
      setNavArr(tempArr);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!loaded) setBar();
    if (props.position) {
      if (props.position[4] < 200) return setActive(false);
      else if (props.position[3] < 200) setUnderline(3);
      else if (props.position[2] < 200) setUnderline(2);
      else if (props.position[1] < 200) setUnderline(1);
      else if (props.position[0] < 200) setUnderline(0);
      setActive(true);
    }
  }, [props.position]);

  return (
    <React.Fragment>
      <header
        className={`${
          mobileMenu && width < 700 ? "mobile-menue-active" : ""
        } flex-just-cent ${
          shouldShowShadow && !mobileMenu ? "header-shadow" : ""
        } ${shouldHideHeader && !mobileMenu ? "header-hidden" : ""}`}
      >
        <div className="flex-align-cent flex-just-btw">
          <div className="header-tem " onClick={() => setMobileMenu(false)}>
            <NavHashLink
              className="header-logo flex-align-cent"
              to="#top"
              smooth
            >
              <Logo />
              <LogoText />
            </NavHashLink>
          </div>

          <div className="header-tem">
            <div className="header-button-wrap flex-align-cent flex-just-rit">
              <nav className="header-nav-wrap">
                <ul className="header-nav flex-align-cent flex-just-cent">
                  {navArr.map((item, i) => {
                    return (
                      <li key={`nav${i}-key`} ref={navArr[i].ref}>
                        <NavHashLink
                          to={`#${
                            item.name != "Home"
                              ? item.name.toLowerCase()
                              : "top"
                          }`}
                          smooth
                          className="nav-text"
                        >
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                        </NavHashLink>{" "}
                      </li>
                    );
                  })}
                </ul>
                <div
                  className={`header-nav-underline flex-just-cent ${
                    active ? "active-tab" : ""
                  }`}
                  style={{
                    width: `${
                      navArr[underline] ? navArr[underline].barWidth : 0
                    }px`,
                    transform: `translateX(
                  ${navArr[underline] ? navArr[underline].barPosition : 0}px)`,
                  }}
                >
                  <div className="header-nav-select" />{" "}
                </div>
              </nav>
              <a
                className=" extra-button"
                target="_blank"
                href="https://www.instagram.com/creatingcallig"
              >
                <button className="header-button line">Follow</button>
              </a>
              <NavHashLink to="/contact#top">
                <button className="header-button primary primary-button">
                  Contact
                </button>
              </NavHashLink>
            </div>
          </div>
          <div
            id="mobile-menu-button"
            onClick={(e) => setMobileMenu(!mobileMenu)}
          >
            <Menu />
          </div>
        </div>
        <nav className="mobile-menu">
          <ul style={{ padding: 0 }} onClick={() => setMobileMenu(false)}>
            <li className="">
              <NavHashLink to="#top " smooth>
                Home
              </NavHashLink>
            </li>
            <li className="">
              <NavHashLink to="#services" smooth>
                Services
              </NavHashLink>
            </li>
            <li className="">
              <NavHashLink to="#products" smooth>
                Products
              </NavHashLink>
            </li>
            <li className="">
              <NavHashLink to="#testimonials" smooth>
                Testimonials
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to="/contact#top" smooth>
                <button className="line">
                  Contact
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                    ></path>
                  </svg>
                </button>
              </NavHashLink>
            </li>
          </ul>

          <div className="header-foot  flex-align-cent flex-just-cent">
            <div
              className="footer-social-links flex-align-cent"
              onClick={() => setMobileMenu(false)}
            >
              <a
                href="https://www.etsy.com/shop/CreatingCallig"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                  fill="currentColor"
                >
                  <path d="M18 2H6C3.8 2 2 3.8 2 6v12c0 2.2 1.8 4 4 4h12c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4zm-1.3 16s-2.3-.1-3.3-.1H9l-2.6.1v-.7l.9-.2c.6-.1.7-.2.7-.7 0 0 .1-1.6.1-4.3 0-2.7-.1-4.4-.1-4.4 0-.5-.2-.6-.8-.7l-.8-.2v-.7l2.6.1h4.9c1 0 2.6-.2 2.6-.2s-.1 1-.1 3.4h-.7l-.2-.8c-.3-1.1-.7-1.6-1.4-1.6h-3.8c-.3 0-.3 0-.3.2v4.4s1.6 0 2.4-.1c.4.1.9-.3.9-.7v-.1l.2-1h.7l-.1 2.1.1 2.2h-.7l-.2-.8c-.1-.5-.5-.8-.9-.8-.6-.1-2.4-.1-2.4-.1V16c0 .7.4 1 1.2 1h2.6c.9.1 1.8-.4 2.1-1.2l.7-1.4h.6c-.1.4-.4 3-.5 3.6z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/creatingcallig/"
                target="_blank"
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                  />
                </svg>
              </a>
              <a href="https://dribbble.com/mdeckar/about" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M16 3C8.823 3 3 8.823 3 16s5.823 13 13 13c7.163 0 13-5.823 13-13S23.163 3 16 3zm8.587 5.992c1.55 1.89 2.481 4.3 2.51 6.91-.367-.071-4.033-.818-7.727-.353-.085-.183-.155-.38-.24-.578a33.027 33.027 0 00-.733-1.608c4.089-1.663 5.95-4.06 6.19-4.37zM16 4.918c2.82 0 5.4 1.057 7.36 2.791-.197.282-1.875 2.524-5.823 4.005-1.819-3.342-3.835-6.077-4.145-6.5A11.292 11.292 0 0116 4.918zM11.277 5.96c.296.395 2.27 3.144 4.117 6.415-5.189 1.382-9.771 1.354-10.265 1.354.72-3.44 3.046-6.303 6.148-7.769zM4.889 16.014v-.338c.48.014 5.866.084 11.407-1.58.324.62.62 1.255.902 1.89-.14.042-.296.084-.437.127-5.724 1.847-8.77 6.895-9.023 7.318a11.078 11.078 0 01-2.849-7.417zM16 27.111c-2.566 0-4.935-.875-6.81-2.34.197-.41 2.453-4.753 8.714-6.938.028-.014.042-.014.07-.028 1.565 4.046 2.2 7.444 2.369 8.417a10.92 10.92 0 01-4.343.889zm6.19-1.904c-.113-.677-.705-3.92-2.158-7.91 3.483-.55 6.529.353 6.91.48-.48 3.087-2.256 5.752-4.752 7.43z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
              <a
                href="mailto:mckenna.creatingcalligraphy@gmail.com"
                target="_blank"
              >
                <svg viewBox="0 0 24 24" fill="currentColor">
                  <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"></path>
                </svg>
              </a>
            </div>
          </div>
        </nav>
      </header>
      <div
        className={`mobile-menu-wrap ${
          mobileMenu && width < 700 ? "mobile-menue-active" : ""
        }`}
        onClick={(e) => setMobileMenu(false)}
      />
    </React.Fragment>
  );
};

export default Comp;

// <li>
//   <NavHashLink to="#top " className="nav-text" smooth>
//     Home
//   </NavHashLink>
// </li>
// <li>
//   <NavHashLink to="#services" className="nav-text" smooth>
//     Services
//   </NavHashLink>
// </li>
// <li>
//   <NavHashLink to="#products" className="nav-text" smooth>
//     Products
//   </NavHashLink>
// </li>
// <li>
//   <NavHashLink to="#testimonials" className="nav-text" smooth>
//     Testimonials
//   </NavHashLink>
// </li>
