import React from "react";
import "./style.css";

//comps
import Card from "./ProductCard";

const Comp = (props) => {
  return (
    <div className="product-cards-wrapper">
      {props.data.map((product) => (
        <Card key={Math.random()} data={product} />
      ))}
    </div>
  );
};

export default Comp;
