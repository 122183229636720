import React from "react";
import "./style.css";

//comps
import Star from "./Star";

//media

const Comp = (props) => {
  function getInitials(input) {
    let name = "ÇFoo Bar 1Name too ÉLong";
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let initials = [...input.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();

    return initials;
  }

  return (
    <div className="testimonial-card flex-col flex-just-btw">
      <div>
        <svg className="testimonial-quote" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z"
          />
        </svg>
        <p className="header-text">{props.data.text}</p>
      </div>
      <div>
        <div className="flex-align-cent">
          <div className="reviews-profile flex-align-cent flex-just-cent">
            {getInitials(props.data.name)}
          </div>
          <div>
            <p className="header-text" style={{ color: "var(--hightlight)" }}>
              <b>{props.data.name}</b>
            </p>
            <p className="header-sub-text test-job">{props.data.title}</p>
          </div>
        </div>
        <div className="flex-align-cent fex-just-cent">
          {props.data.review.map((active) => (
            <Star key={Math.random()} active={active} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comp;
